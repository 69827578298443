<template>
  <vx-card style="padding-left: 10px">
    <!-- <vs-label style="font-weight: bold"> -->

    <!-- </vs-label> -->
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="subtopics"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
      >
        <div
          slot="header"
          class="flex items-center flex-grow"
          vs-type="flex-start"
          vs-justify="flex-start"
        >
          <vx-tooltip text="Add New Topic" position="top" delay=".3s">
            <vs-button
              type="gradient"
              size="small"
              icon-pack="feather"
              @click="addNewTopic()"
              icon="icon-plus"
              color="success"
            ></vs-button>
          </vx-tooltip>
          <vx-tooltip text="Back to Topics" position="top" delay=".3s">
            <vs-button
              type="border"
              size="small"
              class="ml-4"
              color="warning"
              @click="btTopic()"
              >Back to Topics</vs-button
            >
          </vx-tooltip>
        </div>
        <template slot="thead">
          <vs-th sort-key="subTopics">Sub Topics</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th sort-key="action" size="25%">Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].subTopics">{{
              data[indextr].subTopics
            }}</vs-td>

            <vs-td :data="data[indextr].createdAt">{{
              moment(data[indextr].createdAt).format("DD/MM/YYYY")
            }}</vs-td>
            <vs-td :data="data[indextr]._id">
              <vs-row vs-type="flex">
                <vx-tooltip text="FAQs Detail" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="editFaqSubDetail(data[indextr].subTopicsId)"
                    icon="icon-eye"
                    color="success"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip text="Delete FAQs" position="top" delay=".3s">
                  <vs-button
                    class="ml-2"
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="deleteDetailHandler(data[indextr], indextr)"
                    icon="icon-trash"
                    color="primary"
                  ></vs-button>
                </vx-tooltip>
              </vs-row>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <span class="mr-2"
        >{{ currentPage * dataTableParams.limit - (dataTableParams.limit - 1) }}
        -
        {{
          totalDocs - currentPage * dataTableParams.limit > 0
            ? currentPage * dataTableParams.limit
            : totalDocs
        }}
        of {{ totalDocs }}</span
      >
      <vs-pagination
        :total="totalPage"
        v-model="dataTableParams.page"
      ></vs-pagination>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 10,
        topicid: "",
      },
      subtopics: [],
      limitOptions: [5, 10, 25, 50, 100],
      selectedQas: {},
      selectedIndex: "",
    };
  },
  methods: {
    ...mapActions("admin", ["fetchFAQsSubTopic", "deleteFAQsSubTopic"]),
    moment(date) {
      return moment(date);
    },
    getSubTopicsList() {
      let self = this;
      this.fetchFAQsSubTopic(self.dataTableParams).then((res) => {
        self.subtopics = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
      });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getSubTopicsList();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getSubTopicsList();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getSubTopicsList();
    },
    btTopic() {
      this.$router.push({
        name: "FAQs",
      });
    },
    addNewTopic() {
      this.$router.push({
        name: "FAQsSubTopic",
        params: { id: this.dataTableParams.topicid },
      });
    },
    editFaqSubDetail(subid) {
      this.$router.push({
        name: "FAQsSubTopicEdit",
        params: { id: this.dataTableParams.topicid, subid: subid },
      });
    },

    deleteDetailHandler(subt, i) {
      this.selectedQas = subt;
      this.selectedIndex = i;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm Delete`,
        text: `You are about to delete Sub Topic from  "${subt.topic}"`,
        accept: this.confirmDeleteRecord,
        acceptText: "Delete",
      });
    },
    confirmDeleteRecord() {
      let data = {
        faqId: this.dataTableParams.topicid,
        subId: this.selectedQas.subTopicsId,
      };

      this.deleteFAQsSubTopic(data).then((res) => {
        this.$vs.notify({
          title: "Sub Topic Deleted",
          text: res.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.subtopics.splice(this.selectedIndex, 1);
        this.totalDocs -= 1;
      });
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getSubTopicsList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getSubTopicsList();
      }
    },
  },
  created() {
    this.dataTableParams.topicid = this.$route.params.id;
    if (this.dataTableParams.topicid) {
      this.getSubTopicsList();
    } else {
      this.subtopics = [];
    }
  },
};
</script>
